<template>
  <footer class="footer">
    <div class="footer-bottom">
      <p>@ JAMBARAM.XYZ. All Rights Reserved.</p>
      <p>JAMBARAM.XYZ isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends.</p>
      <p>League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.</p>
      <p>CBNU 장호중 mondayy1 / CBNU 유현우 yhw1737</p>
    </div>
  </footer>
</template>

<style scoped>
@media (max-width: 768px){
  .footer {
    display: none;
  }
}

p {
  line-height: 5px;
}

.footer {
  background-color: #1d242f;
  padding: 20px 0;
  bottom: 0;
  left: 0;
}

.footer-bottom {
  border-top: 1px solid #999;
  margin-top: 10px;
  padding: 20px 10px;
  text-align: left;
  font-size: 12px;
  color: #999;
}
</style>
